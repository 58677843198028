class PageloadNotifications {
  constructor() {}
  
  add({selector = '#pageload-notifications', containerSelector = document.body} = {}) {
    const $container = $(containerSelector);
    const $notifications = $container.find(selector).children();
    
    const addNotifications = function(i, el) {
      let $el = $(el);
      
      App.trigger('message', [{
        message: $el.text(),
        type: $el.data('type')
      }]);
    }
    
    $notifications.each(addNotifications);
  }
}

export const pageloadNotifications = new PageloadNotifications();
