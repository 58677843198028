import 'flatpickr';

class DatePicker {
  constructor() {
    this.add();
  }
  
  add({selector = '.date-picker', containerSelector = document.body} = {}) {
    const $container = $(containerSelector);
    const $picker = $container.find(selector);
    
    const addPicker = function(i, el) {
      const $el = $(el);
      
      // Flatpickr pulls data attributes from the instantiated element, so we're only including 
      // data attributes here that aren't read by flatpickr.
      const min = $el.data('min');
      const max = $el.data('max');
      const format = $el.data('format') || 'M d, Y';
      const defaultDate = $el.data('value');
      
      let opts = {
        altInput: true,
        altFormat: format
      };
      
      if (min) opts.minDate = min;
      if (max) opts.maxDate = max;
      if (defaultDate) opts.defaultDate = defaultDate;
      
      const pickr = $el.flatpickr(opts);
      
      $('label[for=' + el.id + ']').each(function() {
        $(this).on('click', function(){
          pickr.open();
        });
      });
    }
    
    $picker.each(addPicker);
  }
}

export const datePicker = new DatePicker();
