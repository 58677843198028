// npm imports
import {formatDistanceToNow} from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import {format as formatDate, utcToZonedTime} from 'date-fns-tz';

function addDateTime(selector) {
  let $el = $(selector);
  
  if ($el.length === 0) return console.warn("'date'" + noElementFound);
  
  // Don't try and manipulate a time element that has already been set
  if ($el.data('time-initialised')) return;
  
  // Get timezone from time element
  const timezone = $el.data('timezone') || 'UTC';
  
  // Get UTC date from datetime attribute
  let date = $el.attr('datetime');
  if (date === '') throw 'Date not found in date tag; doing nothing';
  
  let format = $el.data('format') || 'dd/MM/yyyy HH:mm:ss';
  
  let newDate;
  if (format === 'toNow') {

    var now = new Date();
    var input = new Date(date);
    if(now > input){
      newDate = formatDistanceToNow(input, {
        includeSeconds: true
      }) + ' ago';
    }else{
      newDate = 'in ' + formatDistanceToNow(input, {
        includeSeconds: true
      });
    }
    
  } else {
    let zonedData;

    try {
      zonedData = utcToZonedTime(new Date(date), timezone);
    } catch(e) {
      // IE11 doesn't support Intl formats other than 'UTC'
      // @TODO try to get polyfill working
      zonedData = new Date(date);
    }

    newDate = formatDate(zonedData, format, {timeZone: timezone, locale: enGB});
  }
  
  if (date !== newDate && !$el.data('hide-title')) {
    $el.attr('title', date);
  }
  
  $el.text(newDate);
}

class DateTime {
  constructor() {
    this.add();
  }
  
  add({selector = '[datetime]', containerSelector = document.body} = {}) {
    const $container = $(containerSelector);
    const $datetime = $container.find(selector);
    
    const addTime = function(i, el) {
      addDateTime(el);
    };
    
    $datetime.each(addTime);
  }
}

export const dateTime = new DateTime();
