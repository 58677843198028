const TARGET_NOT_FOUND = '"data-target" target element wasn\'t found';

class ShowOnSelectRadio {
  constructor() {
    this.add();
  }
  
  add({selector = '[type=radio].show-on-select', containerSelector = document.body} = {}) {
    const $container = $(containerSelector);
    const $radios = $container.find(selector);
    
    // Filter elements to avoid adding multiple onchange events.
    // Only add event handler to 'input[name="xyz"] ONCE per set of name="xyz" inputs.
    const addElsToModify = function($els) {
      let elsToModify = [];

      const extractEl = function(i,el) {
        let $el = $(el);
        let elName = $el.attr('name');
        let $allRadios = $(`input[name=${elName}]`);
        

        $allRadios.each((i,el) => {
          if (elsToModify.includes(el)) return;
          
          elsToModify.push(el);
        });
      }

      $els.each(extractEl);
      
      return elsToModify.map(el => $(el));
    }

    const hideOtherTarget = function(i, el) {
      const target = $(el).data('target');

      if (!target) return;

      const $target = $(target);
      
      if ($target.length === 0) return console.warn(TARGET_NOT_FOUND);
      
      $target.addClass('is-hidden');
      $target.find('[data-required]').removeAttr('required');
    }

    const showTarget = function(target) {
      if (!target) return;

      const $target = $(target);

      if ($target.length === 0) return console.warn(TARGET_NOT_FOUND);
      
      $target.removeClass('is-hidden');
      $target.find('[data-required]').attr('required', 'required');
    }
    
    // Add events to filtered elements
    const addShowOnSelectRadio = function($el) {
      const elName = $el.attr('name');
      const target = $el.data('target');
      const $otherEls = $(`input[name=${elName}]`).not($el);
      
      $el.change(() => {
        $otherEls.each(hideOtherTarget);
        
        showTarget(target);
      });
    }
    
    // Get an array of input[name="xyz"] jquery objects
    const els = addElsToModify($radios);

    // Set [data-required] on any required inputs
    els.forEach($el => {
      const target = $el.data('target');
      const $requiredInputs = $(target).find('[required]');
      
      $requiredInputs.attr('data-required', true);
    });

    // Add event change handler for each set of inputs
    els.forEach(addShowOnSelectRadio);

    // Force a 'change' event on first load (used to fix 'required' attributes on containing form elements)
    els.forEach(function($el) {
      $el.filter(':checked').trigger('change');
    });
  }
}

export const showOnSelectRadio = new ShowOnSelectRadio();
