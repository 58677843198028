function addTable(i, el) {
  // In case components.reAdd is called and tries to run on an already-initialized container
  if ($.fn.dataTable.isDataTable(el)) return;
  
  const $table = $(el);
  const searchDisabled = !!$table.data('search-disabled');
  const pagingDisabled = !!$table.data('paging-disabled');
  const emptyData = $table.data('empty');
  const $empty = $(emptyData);
  const languageDefaults = {
    search: '<div class="field"><p class="control has-icons-left">_INPUT_<span class="icon is-small is-left"><i class="fas fa-search"></i></span></p></div>',
    lengthMenu: '<div class="select table-select">_MENU_</div>'
  };
  const itemType = $table.data('item-type');
  const saveState = true;
  
  let itemTypeText = '';
  if (itemType && itemType !== '') {
    itemTypeText = ' ' + itemType;
  }
  
  

  const searchPlaceholder = `Search${itemTypeText}...`;
  let language = Object.assign({}, languageDefaults, {
    searchPlaceholder
  });

  // If data-empty-html is supplied, get html from target element and remove it
  if ($empty.length !== 0) {
    language.emptyTable = $empty.html();
    $empty.remove();
  }

  const filterSearch = searchDisabled ? '': 'f';
  const filterHeader = `<"datatable-filters-header"<"columns is-mobile is-vcentered"<"column is-7"<"datatable-search"${filterSearch}>><"column is-5 dt-header-options"l>>>`;

  let opts = {
    dom: `<"datatable-header"${filterHeader}>tp`,
    language,
    processing: true,
    autoWidth: false,
    orderMulti: false,
    lengthMenu: [
        [ 10, 25, 50 ],
        [ '10 rows', '25 rows', '50 rows' ]
    ],
    displayLength: 25,
    paging: !pagingDisabled,
    drawCallback: function(settings) {
      const api = new $.fn.dataTable.Api(settings);
      const pageInfo = api.page.info()
      const tableDom = api.table().node();
      const $pagination = $(tableDom).next('.dataTables_paginate');
      
      if (pageInfo.pages > 1) {
        $pagination.removeClass('is-hidden');
      } else {
        $pagination.addClass('is-hidden');
      }
    },
    bStateSave: saveState,
    fnStateSave: function(oSettings, oData) {
      localStorage.setItem('DataTables', JSON.stringify(oData));
    },
    fnStateLoad: function(oSettings) {
      return JSON.parse(localStorage.getItem('DataTables'));
    }
  }

  // Default hiding table columns
  opts.columnDefs = [];
  $table.find('thead th').each((i,el) => {
    if(el.getAttribute('data-visible') == 'false'){
      opts.columnDefs.push({ targets: i,visible:false});
    }
  });

  // default orderable table columns
  opts.columnDefs = [];
  $table.find('thead th').each((i,el) => {
    if(el.getAttribute('data-orderable') == 'false'){
      opts.columnDefs.push({ targets: i,orderable:false});
    }
  });
  
  const dt = $table.DataTable(opts);
  
  $('.dataTables_filter input').addClass('input');
  
  const addTableComponents = function() {
    $table.find('[datetime]').each((i, el) => {
      App.components.reAdd(el);
    });

    $table.find('img').each((i,el) => {
      if (el.naturalWidth !== 0) return;

      // Re-set the src on an image for any image that has failed to load
      // (so that the error js can handle a placeholder or remove the media)
      el.setAttribute('src', el.getAttribute('src'));
    });

    const table = dt.table().container();
    App.components.reAdd(table);
  }
  
  const onDraw = function() {
    addTableComponents();
  };
  
  dt.on('draw', onDraw);
}


class Datatables {
  constructor() {
    this.add();
  }

  add({selector = '.datatable', containerSelector = document.body} = {}) {
    const $container = $(containerSelector);
    const $tables = $container.find(selector);
    
    $tables.each(addTable);
  }
}


export const datatables = new Datatables();
