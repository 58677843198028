'use strict';

import '../sass/main.scss';

import {components} from './main/components';

// Add components to DOM
components.add();

App.components = components;
