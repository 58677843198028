// These components initialise when the page is first loaded, attaching to specific elements.
// Component classes are exported with `new` and will only load the constructor once.

// Components that are only loaded once
import {pageloadNotifications} from './components/pageload-notifications';

// Components that can be re-added (these are added to Components store for later re-adding)
import {dateTime} from './components/datetime';
import {datatables} from './components/datatables';
import {datePicker} from './components/date-picker';
import {showOnSelectRadio} from './components/show-on-select-radio';
import {truncate} from './components/truncate';


class Components {
  constructor() {
    this.store = {}
  }

  add() {
    pageloadNotifications.add();
    
    // Components added here that can be readded in the future
    this.store = {
      dateTime,
      datatables,
      datePicker,
      showOnSelectRadio,
      truncate
    };
  }
  
  reAdd(containerSelector = document.body, componentToAdd) {
    if ($(containerSelector).length === 0) {
      console.group();
      console.warn('containerSelector not found - reverting to document.body');
      console.log('supplied containerSelector: ', containerSelector);
      console.groupEnd();
      
      containerSelector = document.body;
    }
    
    if (componentToAdd) {
      //@TODO call specific componentToAdd
    } else {
      for (let component in this.store) {
        this.store[component].add.call(this.store[component], {containerSelector});
      }
    }
  }
}

export const components = new Components();
