/**
 * Truncate an element's text, or number of tags if applied to a .tags element
 * data-limit can be set on the element to set when the truncation is applied
 */

const dataLimit = 140; // 140 characters
const dataTagLimit = 5; // 5 tags


class Truncate {
  constructor() {
    this.add();
  }
  
  add({selector = '.is-truncated', containerSelector = document.body} = {}) {
    const $container = $(containerSelector);
    const $truncate = $container.find(selector);
    
    const addTruncate = function(i, el) {
      const $el = $(el);
      const text = $el.text();
      const limit = $el.data('limit') || dataLimit;
      const textLength = text.length;
      
      if (textLength < limit) return;
      
      const showText = text.slice(0,limit);
      
      const cutText = text.slice(limit+1,text.length);
      const $ellipsis = $('<span>... </span>');
      const $showMoreLink = $('<a href="#" class="show-more-link">show more</a>');
      
      $showMoreLink.click(function(e){
        e.preventDefault();
        
        $ellipsis.remove();
        $showMoreLink.remove();
        
        $el.append(cutText);
      });
      
      $el.empty().append(showText, $ellipsis, $showMoreLink);
    };
    
    const addTagsTruncate = function(i, el) {
      const $container = $(el);
      const $tags = $container.children('.tag');
      const limit = $container.data('limit') || dataTagLimit;
      
      if ($tags.length < limit) return;
      if ($tags.hasClass('truncate-tags-expand')) return;
      
      const $filteredTags = $tags.slice(limit-1);
      const numLeft = $filteredTags.length;
      $filteredTags.hide();
      
      let $btn = $(`<button class="truncate-tags-expand tag is-small button is-light">+ ${numLeft} more</button>`);
      $btn.click(function(){
        $(this).remove();
        $filteredTags.show();
      });
      
      $container.append($btn);
    };
    
    if ($truncate.hasClass('tags')) {
      $truncate.each(addTagsTruncate);
    } else {
      $truncate.each(addTruncate);
    }
  }
}

export const truncate = new Truncate();

